.invalid-row {
  border: #FFCCCC;
    background: #FFCCCC; 
  }
 
  .invalid-row :hover {

    background-color: ivory;
  }
  .valid-row :hover {
    background: #eaf7ea; 
  }

  .valid-input {
    border: none ;
    background-color: #CCFFCC;
  border: none;
  outline: 0.5px solid #1ce81c;
  }

  .invalid-input {
  background-color: ivory;
  border: none;
  outline: 2px solid red;
  border-radius: 4px;
  }